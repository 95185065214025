<template>
  <div>
    <div class="column is-12">
      <label class="label">
        <b>
          <b-field grouped position="is-centered">
            <h1 class="is-size-3">
              Silakan mengambil foto sebelum melanjutkan ke sesi berikutnya
            </h1>
          </b-field>
        </b>
      </label>
    </div>
    <div class="column is-12">
      <b-field grouped position="is-centered">
        <div class="photo" v-if="file">
          <img id="test" :src="file" />
          <b-field grouped position="is-centered">
            <b-button type="is-hcc" @click="modalTakePhoto()"
              >Take a photo</b-button
            >
          </b-field>
        </div>
        <div class="photo" v-else>
          <img src="../../assets/image/user.png" />
          <b-field grouped position="is-centered" class="btn-take-photo">
            <b-button type="is-hcc" size="is-medium" @click="modalTakePhoto()"
              >Ambil Foto</b-button
            >
          </b-field>
        </div>
      </b-field>
    </div>
    <b-modal :active.sync="isTakePhotoModal" :width="400">
      <div id="app" class="card web-camera-container">
        <div class="camera-box" :class="{ flash: isShotPhoto }">
          <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

          <video
            v-show="!isPhotoTaken"
            ref="camera"
            :width="450"
            :height="337.5"
            autoplay
          ></video>

          <canvas
            v-show="isPhotoTaken"
            id="photoTaken"
            ref="canvas"
            :width="450"
            :height="337.5"
          ></canvas>
        </div>

        <div v-if="isCameraOpen && !isLoadingCamera" class="camera-shoot">
          <button type="button" class="button" @click="takePhoto">
            <img
              src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
            />
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    file: {
      default: () => {}
    }
  },
  data() {
    return {
      isCameraOpen: false,
      isLoadingCamera: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isTakePhotoModal: false,
      fileTakePhoto: false
    };
  },
  methods: {
    createCameraElement() {
      this.isLoadingCamera = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingCamera = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoadingCamera = false;
          this.$emit("update-camera", true);
          alert("Kamera tidak ditemukan");
        });
    },
    modalTakePhoto() {
      this.isCameraOpen = true;
      this.createCameraElement();
      this.isTakePhotoModal = true;
    },
    async takePhoto() {
      this.fileTakePhoto = null;
      this.isProcessPhoto = false;
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      this.fileTakePhoto = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg");
      this.$emit("update-file", this.fileTakePhoto);
      this.isProcessPhoto = true;
      this.isTakePhotoModal = false;
      this.stopCameraStream();
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    }
  }
};
</script>

<style>
.btn-take-photo {
  padding-top: 5%;
}
.photo {
  width: 360px;
  padding: 1%;
  border: 1px solid black;
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
