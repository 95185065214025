<template>
  <section class="main" v-if="!isLoading">
    <div v-if="!isLoading">
      <span v-if="!isNotFound && isAuthorized">
        <span v-if="!isCameraOff">
          <span class="is-hidden-desktop header-mobile column is-12">
            <div class="columns is-mobile header-column">
              <div class="column is-3 countdown">
                <a class="navbar-item">
                  <img src="@/assets/logo_hcc.png" width="100" />
                </a>
              </div>
              <div class="column is-6">
                <b-field grouped position="is-centered">
                  <b class="is-size-5" v-if="isSectionUsingCountdown == true">
                    <b class="is-size-5">{{ countdown }}</b>
                  </b>
                </b-field>
                <span class="is-hidden-mobile">
                  <b-field grouped position="is-centered">
                    <b-progress
                      type="is-hcc"
                      style="width: 50%"
                      :value="progress"
                      show-value
                      format="percent"
                    ></b-progress>
                  </b-field>
                </span>
                <span class="is-hidden-desktop">
                  <b-progress
                    type="is-hcc"
                    :value="progress"
                    show-value
                    format="percent"
                  ></b-progress>
                </span>
              </div>
              <div class="column is-3 btn-next">
                <b-field grouped position="is-right">
                  <b-button
                    type="is-hcc"
                    v-if="this.activeStep < 5"
                    @click="
                      activeStep == 4 ? popUpMoveToNextSection() : nextSection()
                    "
                  >
                    Next
                  </b-button>
                </b-field>
              </div>
            </div>
          </span>
          <span class="is-hidden-mobile header-desktop column is-12">
            <div class="columns is-mobile header-column">
              <div class="column is-3 countdown">
                <a class="navbar-item">
                  <img src="@/assets/logo_hcc.png" width="100" />
                </a>
              </div>
              <div class="column is-6">
                <b-field grouped position="is-centered">
                  <b class="is-size-5" v-if="isSectionUsingCountdown == true">
                    <b class="is-size-5">{{ countdown }}</b>
                  </b>
                </b-field>
                <span class="is-hidden-mobile">
                  <b-field grouped position="is-centered">
                    <b-progress
                      type="is-hcc"
                      style="width: 50%"
                      :value="progress"
                      show-value
                      format="percent"
                    ></b-progress>
                  </b-field>
                </span>
                <span class="is-hidden-desktop">
                  <b-progress
                    type="is-hcc"
                    :value="progress"
                    show-value
                    format="percent"
                  ></b-progress>
                </span>
              </div>
              <div class="column is-3 btn-next">
                <b-field grouped position="is-right">
                  <b-button
                    type="is-hcc"
                    v-if="this.activeStep < 5"
                    @click="
                      activeStep == 4 ? popUpMoveToNextSection() : nextSection()
                    "
                  >
                    Next
                  </b-button>
                </b-field>
              </div>
            </div>
          </span>
          <span class="column is-12 content-case-study">
            <b-steps :has-navigation="false" v-model="activeStep" :size="size">
              <b-step-item>
                <TakePhoto
                  :camera="isCameraOff"
                  :file="file"
                  @update-camera="cameraStatus"
                  @update-file="takePhoto"
                />
              </b-step-item>
              <b-step-item>
                <IntroductionPsikotest :introduction="introduction" />
              </b-step-item>
              <b-step-item
                v-for="(page, indexPage) in section"
                :key="indexPage"
              >
                <SectionIntroduction
                  :data="page"
                  v-if="page.type == 'introduction'"
                />
                <SectionTestIntroduction
                  :data="page"
                  v-if="page.type == 'introduction-test'"
                />
                <div class="section-question" v-if="page.type == 'question'">
                  <SectionQuestionCaseStudy :data="page" @clicked="autoSave" />
                </div>
              </b-step-item>
              <b-step-item><ThankYouPage :data="thankYou" /></b-step-item>
            </b-steps>
          </span>
          <!-- <span class="column is-12 progress-bar">
            <span class="columns">
              <span class="column is-5"> </span>
              <span class="column is-2">
                <b-progress
                  type="is-hcc"
                  :value="progress"
                  show-value
                  format="percent"
                ></b-progress>
              </span>
              <span class="column is-5"> </span>
            </span>
          </span> -->
        </span>
        <span v-else>
          <CameraNotFound />
        </span>
      </span>
      <span v-else-if="isNotFound">
        <NotFound />
      </span>
      <span v-else-if="!isAuthorized" class="columns not-found">
        <Unauthorized />
      </span>
    </div>
    <video
      hidden
      ref="cameraHidden"
      :width="450"
      :height="337.5"
      autoplay
    ></video>
    <canvas
      hidden
      id="hiddenCanvas"
      ref="canvasHidden"
      :width="450"
      :height="337.5"
    ></canvas>
    <video ref="screenHidden" id="video" hidden autoplay></video>
  </section>
</template>
<script>
import Unauthorized from '@/components/Unauthorized.vue'
import NotFound from '@/components/NotFound.vue'
import CameraNotFound from '@/components/CameraNotFound.vue'
import TakePhoto from '@/components/Answer/TakePhoto.vue'
import IntroductionPsikotest from '@/components/Answer/IntroductionPsikotest.vue'
import SectionIntroduction from '@/components/Answer/SectionIntroduction.vue'
import SectionTestIntroduction from '@/components/Answer/SectionTestIntroduction.vue'
import SectionQuestionCaseStudy from '@/components/Answer/SectionQuestionCaseStudy.vue'
import ThankYouPage from '@/components/Answer/ThankYouPage.vue'

export default {
  components: {
    Unauthorized,
    NotFound,
    CameraNotFound,
    TakePhoto,
    IntroductionPsikotest,
    SectionIntroduction,
    SectionTestIntroduction,
    SectionQuestionCaseStudy,
    ThankYouPage,
  },
  data() {
    return {
      isLoading: true,
      isAuthorized: true,
      isCameraActive: true,
      isAutoSave: true,
      isScreenCaptureActive: true,
      isSectionUsingCountdown: false,
      isTugasActive: false,
      introduction: null,
      isNotFound: false,
      isCameraOff: false,
      isTestContinue: false,
      section: {},
      sectionLength: 5,
      questionNo: 0,
      activeStep: 0,
      size: 'small',
      file: null,
      countdown: 0,
      timerInterval: 0,
      updateSeconds: 0,
      thankYou: [],
      seconds: 0,
      progress: 0,
    }
  },
  created() {
    this.fetchData()

    // // for disable print screen
    // document.addEventListener("keyup", function(e) {
    //   var keyCode = e.keyCode ? e.keyCode : e.which;
    //   if (keyCode == 44) {
    //     stopPrntScr();
    //   }
    // });
    // function stopPrntScr() {
    //   var inpFld = document.createElement("input");
    //   inpFld.setAttribute("value", ".");
    //   inpFld.setAttribute("width", "0");
    //   inpFld.style.height = "0px";
    //   inpFld.style.width = "0px";
    //   inpFld.style.border = "0px";
    //   document.body.appendChild(inpFld);
    //   inpFld.select();
    //   document.execCommand("copy");
    //   inpFld.remove(inpFld);
    // }
  },
  watch: {
    activeStep: function(val) {
      if (val > 2) {
        this.isSectionUsingCountdown = false
        this.isCountDown = false
        this.seconds = 0
        this.countdown = 0
        this.progress = 100
        clearInterval(this.timerInterval)
        if (val != this.sectionLength && !this.isLoading) {
          if (val == 4) {
            //for countdown
            this.isSectionUsingCountdown = true
            this.seconds = this.section[2] ? this.section[2].duration : 0
            if (localStorage.getItem('time') != undefined) {
              this.seconds = localStorage.getItem('time')
            }
            this.isCountDown = true
            this.timerInterval = setInterval(() => this.timer(), 1000)

            // if (!this.isLoading) {
            //for camera capture
            this.isCameraOpen = true
            this.createHiddenCameraElement()
            this.interval = setInterval(
              () => this.takeScreenShot(),
              this.section[2].intervalTakeCandidatPhoto * 1000
            )

            //for screen capture
            this.startRecording(this.section[2].intervalTakeCandidatPhoto)

            setInterval(() => this.autoSave(), 10 * 1000)
            // }
          }
        }
        if (val == 5) {
          this.autoSave()
          this.isAutoSave = false
        }
      }
      if (val == 5) {
        this.stopCameraStream()
        this.stopScreenCapture()
        this.isCameraActive = false
        this.isScreenCaptureActive = false
      }
      this.progress = (val / 5) * 100

      if (this.progress >= 100) {
        this.$store.dispatch('getDataToSendCaseStudy', {
          nit: this.$route.query.nit,
          nama: this.$route.query.nama,
          email: this.$route.query.email,
          cid: this.$route.query.cid,
          uid: this.$route.query.uid,
          case_study_id: this.$route.params.caseStudyId,
          id_tes: this.$route.query.id_tes,
          app_url: this.$route.query.app_url,
        })
      }
    },
  },
  methods: {
    nextSection() {
      if (localStorage.removeItem('time')) {
        localStorage.removeItem('time')
      }

      if (this.activeStep == 0) {
        if (this.file != null) {
          this.activeStep += 1
        } else {
          this.danger(
            'Silahkan mengambil foto sebelum melanjutkan ke sesi berikutnya'
          )
        }
      } else {
        this.activeStep += 1
      }
    },
    takePhoto(photo) {
      this.file = photo
    },
    cameraStatus(status) {
      this.isCameraOff = status
    },
    async fetchData() {
      this.isLoading = true
      const responseToken = await this.$store.dispatch(
        'saveClientAccessToken',
        this.$route.query.token
      )
      if (responseToken) {
        var content = []
        content.nit = this.$route.query.nit
        content.caseStudyId = this.$route.params.caseStudyId
        content.email = this.$route.query.email
        const responseCaseStudyTest = await this.$store
          .dispatch('getCaseStudyTest', content)
          .catch(error => {
            if (error.response.status == 401) {
              this.isAuthorized = false
              return error
            }
          })
        if (this.isAuthorized == true) {
          this.isNotFound = true
          if (responseCaseStudyTest.status == 200) {
            this.section = responseCaseStudyTest.data.caseStudySection.section
            if (responseCaseStudyTest.data.caseStudySection.section[0]) {
              responseCaseStudyTest.data.caseStudySection.section[0].description = responseCaseStudyTest.data.caseStudySection.section[0].description.replace(
                '#nama#',
                this.$route.query.nama
              )
            }
            this.seconds = this.section[2] ? this.section[2].duration : 0
            if (localStorage.getItem('time') != undefined) {
              this.seconds = localStorage.getItem('time')
            }
            this.activeStep = responseCaseStudyTest.data.activeStep
            this.progress = (this.activeStep / 5) * 100
            this.sectionLength = responseCaseStudyTest.data.sectionLength
            this.thankYou = responseCaseStudyTest.data.thankYou
            const psikotestIntroductionResponse = await this.$store.dispatch(
              'getClientPsikotestIntroductionCaseStudy'
            )
            psikotestIntroductionResponse.data.rules = psikotestIntroductionResponse.data.rules.replace(
              '#nama#',
              this.$route.query.nama
            )
            if (
              this.isAuthorized == true &&
              psikotestIntroductionResponse.status == 200
            ) {
              this.introduction = psikotestIntroductionResponse.data
              var path = JSON.parse(this.introduction.bannerImages).file_url
              this.introduction.imageUrl =
                "'" + process.env.VUE_APP_BACKEND_URL + path + "'"
              this.isNotFound = false
            }
          }
        }
        if (responseCaseStudyTest.data.status == 'update') {
          if (this.activeStep == 4) {
            this.isSectionUsingCountdown = true
            this.seconds = this.section[2] ? this.section[2].duration : 0
            if (localStorage.getItem('time') != undefined) {
              this.seconds = localStorage.getItem('time')
            }
            this.isCountDown = true
            this.timerInterval = setInterval(() => this.timer(), 1000)

            this.isCameraOpen = true
            this.createHiddenCameraElement()
            this.interval = setInterval(
              () => this.takeScreenShot(),
              this.section[2].intervalTakeCandidatPhoto * 1000
            )

            //for screen capture
            this.startRecording(this.section[2].intervalTakeCandidatPhoto)

            setInterval(() => this.autoSave(), 10 * 1000)
          }
        }
      }

      this.isLoading = false
    },
    updateRemainingTime(time) {
      this.seconds = time
    },
    //for auto save
    autoSave() {
      if (this.isAutoSave == true) {
        var content = []
        content.nit = this.$route.query.nit
        content.caseStudyId = this.$route.params.caseStudyId
        content.answer = this.section
        content.time = this.seconds
        content.activeStep = this.activeStep
        content.uid = this.$route.query.uid
        content.cid = this.$route.query.cid
        content.email = this.$route.query.email
        content.id_tes = this.$route.query.id_tes
        content.app_url = this.$route.query.app_url
        content.name = this.$route.query.nama
        if (this.progress == 100) {
          content.finish = 'Yes'
        } else {
          content.finish = 'No'
        }
        this.$store.dispatch('autoSaveCaseStudy', content)
      }
    },

    //for take screen capture
    async startRecording(time) {
      if (this.isAutoSave == true) {
        const displayMediaOptions = {
          video: {
            mediaSource: 'screen',
            cursor: 'always',
          },
          videoBitsPerSecond: 1,
          audio: false,
        }

        const stream = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        )
        stream.getVideoTracks()[0].addEventListener('ended', () => {
          clearInterval(this.ssInterval)
        })
        stream.onended = function() {
          clearInterval(this.ssInterval)
        }

        const video = document.getElementById('video')
        video.onloadedmetadata = () => {
          video.play()
        }

        video.srcObject = stream
        this.captureSS(time)
      }
    },

    async captureSS(time) {
      if (this.isAutoSave == true) {
        this.ssInterval = setInterval(() => {
          var canvas = document.createElement('canvas')
          canvas.width = 1280
          canvas.height = 720
          var ctx = canvas.getContext('2d')

          // Draw video on canvas
          const video = document.getElementById('video')
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

          const img = canvas.toDataURL('image/jpeg')
          this.storeHiddenScreenShot(img)
        }, time * 1000)
      }
    },
    async storeHiddenScreenShot(img) {
      if (this.isAutoSave == true) {
        var content = []
        content.image = img
        content.nit = this.$route.query.nit
        content.caseStudyId = this.$route.params.caseStudyId

        await this.$store.dispatch('storeHiddenScreenCaptureCaseStudy', content)
      }
    },
    stopCameraStream() {
      let tracks = this.$refs.cameraHidden.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },
    stopScreenCapture() {
      let tracks = this.$refs.screenHidden.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },
    //for take camera capture
    async takeScreenShot() {
      if (this.isCameraActive) {
        this.isShotPhoto = true
        const FLASH_TIMEOUT = 50
        setTimeout(() => {
          this.isShotPhoto = false
        }, FLASH_TIMEOUT)
        const context = this.$refs.canvasHidden.getContext('2d')
        context.drawImage(this.$refs.cameraHidden, 0, 0, 450, 337.5)
        var image = document.getElementById('hiddenCanvas')
        var content = []
        content.image = image.toDataURL()
        content.nit = this.$route.query.nit
        content.caseStudyId = this.$route.params.caseStudyId
        await this.$store.dispatch('storeHiddenPhotoCaseStudy', content)
      }
    },
    createHiddenCameraElement() {
      this.isLoadingCamera = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingCamera = false
          this.$refs.cameraHidden.srcObject = stream
        })
        .catch(() => {
          this.isLoadingCamera = false
          this.isCameraOff = true
          alert('Kamera tidak ditemukan')
        })
    },

    timer() {
      var hoursLeft = Math.floor(this.seconds - 0 * 86400)
      var hours = Math.floor(hoursLeft / 3600)
      var minutesLeft = Math.floor(hoursLeft - hours * 3600)
      var minutes = Math.floor(minutesLeft / 60)
      var remainingSeconds = this.seconds % 60
      this.countdown =
        this.pad(hours) +
        ':' +
        this.pad(minutes) +
        ':' +
        this.pad(remainingSeconds)
      localStorage.setItem('time', this.seconds)
      if (this.seconds != 0) {
        this.seconds = this.seconds - 1
      } else {
        this.isCountDown = false

        this.seconds = 0
        this.countdown = ''
        this.activeStep += 1
      }
    },
    pad(n) {
      return n < 10 ? '0' + n : n
    },
    popUpMoveToNextSection() {
      this.$buefy.dialog.confirm({
        title: 'Move To other section',
        message: `Anda akan berpindah ke halaman berikutnya. Apakah Anda sudah yakin dengan seluruh jawaban Anda?`,
        cancelText: 'Belum, saya ingin mengeceknya kembali.',
        confirmText: 'Sudah, saya ingin berpindah ke halaman berikutnya',
        type: 'is-success',
        onConfirm: () => this.nextSection(),
      })
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
<style lang="scss">
/* html, body, .height {
  height: 100vh !important;
} */
@media (min-width: 360px) and (max-width: 1000px) {
  .toast {
    width: 95%;
  }
  .modal-card-foot > button {
    height: auto !important;
    font-size: 1.25rem !important;
    white-space: normal !important;
  }
}
@media (min-width: 360px) {
  .content-case-study {
    padding-top: 15%;
    padding-left: 0;
    padding-right: 0;
  }
  .b-steps .steps + .step-content {
    padding-top: 19px !important;
    height: 83vh;
  }
  .section-question {
    padding-top: 10px !important;
  }
}
@media screen and (min-width: 1216px) {
  .content-case-study {
    max-height: 83vh !important;
    height: 83vh !important;
    padding-left: 0;
    padding-right: 0;
    padding-top: 4%;
  }
  .b-steps .steps + .step-content {
    padding-top: 19px !important;
    height: 83vh;
  }
  .section-question {
    padding-top: 30px !important;
  }
}
@media screen and (min-width: 1408px) {
  .content-case-study {
    max-height: 85vh !important;
    height: 100% !important;
    padding-left: 0;
    padding-right: 0;
  }
  .b-steps .steps + .step-content {
    padding-top: 0 !important;
    height: 83vh;
  }
  .section-question {
    padding-top: 30px !important;
  }
}

.b-steps .steps {
  display: none;
}
.header {
  &-mobile {
    overflow: hidden;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    padding-top: 1%;
    height: 10%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }

  &-desktop {
    overflow: hidden;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin-left: 0;
    padding-top: 1%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }
}

/* .header-column {
  padding-top: 1% !important;
  padding-right: 1% !important;
  padding-right: 1% !important;
} */
.main {
  padding: 0% !important;
}

.countdown {
  padding-left: 2%;
}
@media (min-width: 360px) {
  .progress-bar {
    /* border-top: 2px solid #dbdbdb; */
    bottom: 0;
    padding-bottom: 0;
    height: 100%;
  }
}
@media screen and (min-width: 1216px) {
  .progress-bar {
    border-top: 2px solid #dbdbdb;
    bottom: 0;
    padding-bottom: 0;
    height: 100%;
  }
  .progress {
    height: 18px !important;
  }
}
@media screen and (min-width: 1408px) {
  .progress-bar {
    border-top: 2px solid #dbdbdb;
    bottom: 0;
    padding-bottom: 0;
    padding-top: 1.6%;
    height: 8vh;
  }
  .progress {
    height: 22px !important;
  }
}

.progress-wrapper .progress-value {
  top: 13% !important;
  font-size: 15px !important;
}
</style>
